import React from "react";
import { Container } from "react-bootstrap";

const Terms = () => {
  return (
    <main className="py-5 my-5 about">
      <h1 className="text-center">Our Terms & Conditions</h1>
      <Container>
        <h3>APPLICABLE LEGISLATION</h3>
        <p className="first-p">
          Conditions of carriage as prescribed by the legislation and
          regulations in force in the jurisdiction at the time and place of
          shipment are hereby incorporated by reference and apply to carrier
          services performed pursuant to this bill of lading/waybill.
        </p>
        <h3>LOSS, DELAY AND LIMITATION OF LIABILITY</h3>
        <p>
          Unless specifically agreed to in writing prior to shipping the goods,
          the carrier shall not:
        </p>
        <ol>
          <li>
            be liable for any special, incidental, consequential, indirect or
            other damages, including without being limited to loss of earnings,
            loss of profits or penalties of any kind caused by misdelivery,
            failure to deliver or delay in delivery of a shipment, or part
            thereof, regardless of the cause of such misdelivery, failure of
            delay, including but not limited to negligence of the Carrier, its
            servants and agents, and fundamental breach of contract;
          </li>
          <li>
            be liable for any loss, damage or injury to a shipment, or part
            thereof, in excess of $2.00 per pound ($4.41 per kilogram) unless a
            higher value is declared on the face of the bill of lading/waybill
            by the Shipper.
          </li>
        </ol>
        <p>This limitation of liability shall apply notwithstanding;</p>
        <ol>
          <li>
            any disclosure of the nature of extraordinary value of the goods
            shipped;
          </li>
          <li>
            the amount of any loss, damage or injury including, without being
            limited to, special, incidental, consequential or indirect damages,
            including loss of earnings, loss of profit, or penalties of any
            nature whatsoever.
          </li>
        </ol>
        <h3>INSURANCE</h3>
        <p>
          Additional insurance of 50 cent per $100.00 (or fraction therof) will
          be assessed on the declared value (if any). The Shipper has the sole
          responsibility for insuring the goods above the maximum available from
          the Carrier and the Carrier bears no responsibility for same. ​
        </p>
        <h3>NOTICE OF DAMAGE OR LOSS</h3>
        <p>
          Any loss, damage or injury must be noted on the bill of lading/waybill
          at any time of delivery otherwise the Consignee's signature will
          constitute conclusive proof of goods having been delivered and
          received in good order and condition; except in the case of concealed
          loss, damage or injury, in which case notification of same must be
          received at any office of the Carrier within 48 hours after delivery
          and receipt of the goods. The Carrier shall not be liable for loss,
          damage or injury to any of the goods carried pursuant to this bill of
          lading/waybill unless notice thereof, setting out particulars of the
          origin, destination and date of shipment of the goods and the
          estimated amount claimed in respect of such loss, damage or injury is
          given in writing to and received by the originating or delivering
          carrier at any office of the Carrier within 60 days after delivery of
          the goods or, in the case of failure to make delivery within ninety
          days from the date of shipment of the goods. Further, the Carrier
          shall not be liable unless the final statement of claim is filed
          within nine months from the date of shipment of the goods together
          with a copy of the paid freight bill.
        </p>
        <h3>DANGEROUS GOODS</h3>
        <p>
          The Carrier shall not be liable for any loss, damage, injury or
          failure to perform to and arising from goods that are prohibited,
          restricted or required to be carried in special containers by CTC,
          IATA or otherwise, unless the Shipper fully discloses the nature of
          the dangers goods and same have been properly contained and labelled
          in conformity with the applicable federal and provincial statutes. The
          Shipper hereby agrees to indemnify and save harmless the carrier for
          all costs and damages of any nature whatsoever for its failure to
          disclose, label and/or properly contain dangerous goods.
        </p>
        <h3>​ ENTIRE AGREEMENT</h3>
        <p>
          This bill of lading/waybill constitutes the entire agreement between
          the parties and no servant, agent or representative of the carrier is
          authorized to add to, alter, modify or waive any provision of this
          agreement.
        </p>

        <p className="d-block mx-auto py-5">
          <b>
            NOTE: CARRIER DOES NOT GUARANTEE DELIVERY TIMES, INCLUDING BUT NOT
            LIMITED TO DELIVERY TIMES FOR TENDERS.
          </b>
        </p>
      </Container>
    </main>
  );
};

export default Terms;
