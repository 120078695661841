import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import useAdmin from "./components/useAdmin";
import About from "./screens/About";
import Auth from "./screens/Auth";
import Contact from "./screens/Contact";
import Create from "./screens/Create";
import Dashboard from "./screens/Dashboard";
import Delivery from "./screens/Delivery";
import Forget from "./screens/Forgot";
import Forgot from "./screens/Forgot";
import Home from "./screens/Home";
import NotFound from "./screens/NotFound";
import Profile from "./screens/Profile";
import ResetPassword from "./screens/Reset";
import Terms from "./screens/Terms";

function App() {
  const admin = useAdmin();

  const { userInfo } = useSelector((state) => state.userLogin);

  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/auth" element={<Auth />} />
          <Route
            path="/user/forgot/:id"
            element={userInfo ? <Home /> : <Forget />}
          />
          <Route
            path="/reset/password/:token"
            element={userInfo ? <Home /> : <ResetPassword />}
          />
          <Route path="/profile" element={!userInfo ? <Home /> : <Profile />} />

          <Route path="/dashboard" element={admin ? <Dashboard /> : <Home />} />
          <Route
            path="/create-delivery"
            element={admin ? <Create /> : <Home />}
          />
          <Route path="/delivery/:id" element={<Delivery />} />
          <Route path="/about" element={<About />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/user/forgot" element={<Forgot />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
