export const GET_ACTIVE_DELIVERY_REQUEST = "GET_ACTIVE_DELIVERY_REQUEST";
export const GET_ACTIVE_DELIVERY_SUCCESS = "GET_ACTIVE_DELIVERY_SUCCESS";
export const GET_ACTIVE_DELIVERY_FAIL = "GET_DELIVERY_FAIL";

export const DELETE_ACTIVE_DELIVERY_REQUEST = "DELETE_ACTIVE_DELIVERY_REQUEST";
export const DELETE_ACTIVE_DELIVERY_SUCCESS = "DELETE_ACTIVE_DELIVERY_SUCCESS";
export const DELETE_ACTIVE_DELIVERY_FAIL = "DELETE_ACTIVE_DELIVERY_FAIL";

export const GET_DELIVERY_REQUEST = "GET_DELIVERY_REQUEST";
export const GET_DELIVERY_SUCCESS = "GET_DELIVERY_SUCCESS";
export const GET_DELIVERY_FAIL = "GET_DELIVERY_FAIL";
export const GET_DELIVERY_RESET = "GET_DELIVERY_RESET";

export const CREATE_DELIVERY_REQUEST = "CREATE_DELIVERY_REQUEST";
export const CREATE_DELIVERY_SUCCESS = "CREATE_DELIVERY_SUCCESS";
export const CREATE_DELIVERY_FAIL = "CREATE_DELIVERY_FAIL";
export const CREATE_DELIVERY_RESET = "CREATE_DELIVERY_RESET";

export const ADD_IMAGE_REQUEST = "ADD_IMAGE_REQUEST";
export const ADD_IMAGE_SUCCESS = "ADD_IMAGE_SUCCESS";
export const ADD_IMAGE_FAIL = "ADD_IMAGE_FAIL";
export const ADD_IMAGE_RESET = "ADD_IMAGE_RESET";

export const MODIFY_DELIVERY_STATUS_REQUEST = "MODIFY_DELIVERY_STATUS_REQUEST";
export const MODIFY_DELIVERY_STATUS_SUCCESS = "MODIFY_DELIVERY_STATUS_SUCCESS";
export const MODIFY_DELIVERY_STATUS_FAIL = "MODIFY_DELIVERY_STATUS_FAIL";
export const MODIFY_DELIVERY_STATUS_RESET = "MODIFY_DELIVERY_STATUS_RESET";

export const MODIFY_DELIVERY_LOCATION_REQUEST = "MODIFY_DELIVERY_LOCATION_REQUEST";
export const MODIFY_DELIVERY_LOCATION_SUCCESS = "MODIFY_DELIVERY_LOCATION_SUCCESS";
export const MODIFY_DELIVERY_LOCATION_FAIL = "MODIFY_DELIVERY_LOCATION_FAIL";
export const MODIFY_DELIVERY_LOCATION_RESET = "MODIFY_DELIVERY_LOCATION_RESET";
