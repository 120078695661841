import { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { modifyDeliveryStatus } from "../actions/deliveryActions";
import { useDispatch, useSelector } from "react-redux";
import { FaTimes } from "react-icons/fa";
import Loader from "./Loader";

const deliveryStatusOptions = [
  "pending",
  "picked-up",
  "on-hold",
  "out-for-delivery",
  "cancelled",
  "in-transit",
  "enroute",
  "delivered",
  "returned",
];

const ModifyStatusForm = ({ _id, setShowForm }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");

  const { loading: loadingModify } = useSelector(
    (state) => state.modifyDeliveryStatus
  );

  return (
    <>
      {loadingModify ? (
        <Loader color="black" height="30px" width="30px" />
      ) : (
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            dispatch(modifyDeliveryStatus(_id, status, message));
          }}
        >
          <FaTimes
            size={20}
            onClick={() => setShowForm(false)}
            color="crimson"
            className="mb-2 "
            title="close"
            style={{ cursor: "pointer", float: "right" }}
          />
          <Form.Group controlId="select-status" className="my-2">
            <Form.Select
              required
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <option value="">Select status</option>
              {deliveryStatusOptions.map((x) => {
                return (
                  <option value={x} key={x}>
                    {x}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
          <Form.Group controlId="message-status">
            <Form.Control
              placeholder="Enter message *"
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </Form.Group>
          <Button
            size="sm"
            type="submit"
            className="my-2 ml-5 border"
            style={{ marginLeft: "5rem" }}
          >
            Modify
          </Button>
        </Form>
      )}
    </>
  );
};

export default ModifyStatusForm;
