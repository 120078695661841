import React, { useState, useEffect } from "react";
import { Col, Container, Form, Row, Button, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createDelivery } from "../actions/deliveryActions";

import Loader from "../components/Loader";
import Message from "../components/Message";
import { CREATE_DELIVERY_RESET } from "../constants/deliveryConstants";

const Create = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [productName, setProductName] = useState("");
  const [deliveryTime, setDeliveryTime] = useState("");
  const [receiverName, setReceiverName] = useState("");
  const [receiverAddress, setReceiverAddress] = useState("");
  const [receiverEmail, setReceiverEmail] = useState("");
  const [size, setSize] = useState(0);
  const [senderName, setSenderName] = useState("");
  const [senderAddress, setSenderAddress] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [origin, setOrigin] = useState("");
  const [pickupDate, setPickupDate] = useState("");
  const [transportMode, setTransportMode] = useState("");
  const [images, setImages] = useState([]);

  const { loading, success, serverReply, error } = useSelector(
    (state) => state.deliveryCreate
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    const receiver = {
      name: receiverName,
      address: receiverAddress,
      email: receiverEmail,
    };
    const sender = {
      name: senderName,
      address: senderAddress,
      email: senderEmail,
    };

    dispatch(
      createDelivery(
        productName,
        deliveryTime,
        receiver,
        size,
        sender,
        origin,
        pickupDate,
        transportMode,
        images
      )
    );
  };

  useEffect(() => {
    let timeout;
    if (success) {
      timeout = setTimeout(() => {
        navigate("/delivery/" + serverReply?.data?.createDelivery?._id);
        dispatch({ type: CREATE_DELIVERY_RESET });
      }, 3000);
    }

    return () => clearTimeout(timeout);
  }, [success, serverReply, navigate, dispatch]);

  return (
    <main className="py-5 my-5">
      <Container className="border py-5">
        <h1 className="text-center py-5">Create Delivery</h1>

        <Form onSubmit={handleSubmit}>
          {loading && <Loader />}
          {error && <Message variant="danger">{error}</Message>}
          {success && (
            <Message varaint="success">{serverReply?.message}</Message>
          )}
          <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
            {images &&
              images.map((i, index) => (
                <Image
                  key={index}
                  src={URL.createObjectURL(i)}
                  width={300}
                  height={300}
                  style={{ objectFit: "cover" }}
                />
              ))}
          </div>
          <Form.Group>
            <Form.Label htmlFor="coverImage">Select Product Images</Form.Label>
            <Form.Control
              type="file"
              accept="/image/*"
              onChange={(e) => {
                setImages([...e.target.files]);
                console.log([e.target.files]);
              }}
              required
              autoFocus
              name="image"
              id="image"
              multiple
            />
          </Form.Group>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridProduct">
              <Form.Label>Product Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter product name"
                onChange={(e) => setProductName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridDTime">
              <Form.Label>Delivery Time</Form.Label>
              <Form.Control
                type="datetime-local"
                placeholder="Select delivery time"
                onChange={(e) => setDeliveryTime(e.target.value)}
                required
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridRName">
              <Form.Label>Receiver Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter receiver name"
                onChange={(e) => setReceiverName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridRAddress">
              <Form.Label>Receiver Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter receiver address"
                onChange={(e) => setReceiverAddress(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridRAddress">
              <Form.Label>Receiver Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter receiver email"
                onChange={(e) => setReceiverEmail(e.target.value)}
                required
              />
            </Form.Group>
          </Row>

          <Form.Group className="mb-3" controlId="formGridSize">
            <Form.Label>Size</Form.Label>
            <Form.Control
              type="number"
              placeholder="e.g 2kg"
              onChange={(e) => setSize(e.target.value)}
              required
            />
          </Form.Group>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridRName">
              <Form.Label>Sender Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Sender name"
                onChange={(e) => setSenderName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridRAddress">
              <Form.Label>Sender Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Sender address"
                onChange={(e) => setSenderAddress(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridRAddress">
              <Form.Label>Sender Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter Sender email"
                onChange={(e) => setSenderEmail(e.target.value)}
                required
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>Origin</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter origin address"
                onChange={(e) => setOrigin(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridState">
              <Form.Label>Transport Mode</Form.Label>
              <Form.Select
                onChange={(e) => setTransportMode(e.target.value)}
                required
              >
                <option>Choose...</option>
                <option value="Airplane">Airplane</option>
                <option value="Ship">Ship</option>
                <option value="Trailer">Trailer</option>
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridZip">
              <Form.Label>Pickup Date</Form.Label>
              <Form.Control
                type="date"
                onChange={(e) => setPickupDate(e.target.value)}
                required
              />
            </Form.Group>
          </Row>

          <Button variant="primary" type="submit" className="d-block mx-auto">
            Submit
          </Button>
        </Form>
      </Container>
    </main>
  );
};

export default Create;
