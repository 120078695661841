import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {
  FaEnvelope,
  FaSearchLocation,
  FaPhone,
  FaWhatsapp,
} from 'react-icons/fa';

import { variables } from '../data/variables';

const Contact = () => {
  const handleFormSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission

    const form = event.target;
    const formData = new FormData(form);

    // Send form data to Formspree
    fetch(form.action, {
      method: form.method,
      body: formData,
      headers: {
        Accept: 'application/json'
      }
    }).then(response => {
      if (response.ok) {
        // Clear the form fields
        form.reset();
        alert('Thank you for your message!');
      } else {
        alert('Oops! There was a problem submitting your form');
      }
    }).catch(error => {
      alert('Oops! There was a problem submitting your form');
    });
  };

  return (
    <main className="py-5 my- about contact-background">
      <Container>
        <Row>
          <Col>
            <h1 className="text-center mb-5 text-light">Get In Touch</h1>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <div className="contact p-4 rounded">
              <div className="mx-auto my-4">
                <a href={'mailto:' + variables.email} className="text-dark">
                  <FaEnvelope size={40} className="me-2" />
                  {variables.email}
                </a>
              </div>
              <div className="mx-auto my-4" id="uk">
                <FaSearchLocation size={40} className="me-2 " />
                {variables.address}
              </div>
              <div className="mx-auto my-4">
                <a href={'tel:' + variables.phoneNumber} className="text-dark">
                  <FaPhone size={40} className="me-2" />
                  {variables.phoneNumber}
                </a>
              </div>
              <div className="mx-auto my-4">
                <a
                  href={
                    'https://api.whatsapp.com/send?phone=' +
                    variables.phoneNumber
                  }
                  className="text-success"
                >
                  <FaWhatsapp size={40} className="me-2" />
                  {variables.phoneNumber}
                </a>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="form-container p-4 rounded contactForm">
              <h3 className="text-center mb-4">Contact Form</h3>
              <form action="https://formspree.io/f/mrgnnjlv" method="POST" onSubmit={handleFormSubmit}>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Your Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Enter your name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="Enter your email"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="message" className="form-label">
                    Message
                  </label>
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    rows="5"
                    placeholder="Enter your message"
                    required
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default Contact;
