import React, { useEffect, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getActiveDelivery } from "../actions/deliveryActions";

import { LinkContainer } from "react-router-bootstrap";
import Loader from "./Loader";
import Message from "./Message";
import Paginate from "./Pagination";
import ActiveDeliveryTr from "./ActiveDeliveryTr";

const ActiveDelivery = () => {
  const dispatch = useDispatch();
  const [list, setList] = useState(null);
  const [pages, setPages] = useState(null);
  const [page, setPage] = useState(null);

  const { loading, success, serverReply, error } = useSelector(
    (state) => state.activeDeliveryGet
  );
  const {
    // loading: loadingModify,
    success: successModify,
    serverReply: serverReplyModify,
    error: errorModify,
  } = useSelector((state) => state.modifyDeliveryStatus);
  const {
    // loading: loadingModifyLocation,
    success: successModifyLocation,
    serverReply: serverReplyModifyLocation,
    error: errorModifyLocation,
  } = useSelector((state) => state.modifyDeliveryLocation);

  const {
    loading: dLoading,
    success: dSuccess,
    // serverReply: dServerReply,
    error: dError,
  } = useSelector((state) => state.deliveryDelete);

  useEffect(() => {
    dispatch(getActiveDelivery());
  }, [dispatch, dSuccess, successModify, successModifyLocation]);

  useEffect(() => {
    if (success) {
      setList(serverReply?.data?.allDeliveries);
      setPage(serverReply?.data?.page);

      setPages(serverReply?.data?.pages);
    }
  }, [success, serverReply]);

  return (
    <div>
      <Container>
        <h2 className="text-center py-5">All Deliveries</h2>
        {loading && <Loader color="black" />}
        {dLoading && <Loader color="black" />}
        {error && <Message variant="danger">{error}</Message>}
        {dError && <Message variant="danger">{dError}</Message>}
        {successModify && (
          <Message variant="success">{serverReplyModify?.message}</Message>
        )}
        {errorModify && <Message variant="danger">{errorModify}</Message>}
        {successModifyLocation && (
          <Message variant="success">
            {serverReplyModifyLocation?.message}
          </Message>
        )}
        {errorModifyLocation && (
          <Message variant="danger">{errorModifyLocation}</Message>
        )}
        <LinkContainer to="/create-delivery" className="my-3">
          <Button variant="outline-primary">Create New Delivery</Button>
        </LinkContainer>
        <Table striped bordered hover responsive size="sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Tracking code</th>
              <th>Admin</th>
              <th colSpan={2}>
                Delivery Time <br />
                <span className="px-5 " style={{ display: "inline-block" }}>
                  Start
                </span>
                <span style={{ display: "inline-block" }}>End</span>
              </th>
              <th>Receiver</th>
              <th>Product Name</th>
              <th>Delivered</th>
              <th>Status</th>
              <th colSpan={2}>Modify Status</th>
              <th>Location</th>
              <th colSpan={2}>Modify Location</th>

              <th>Address</th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list.map((x, i) => {
                return <ActiveDeliveryTr x={x} i={i} key={i} />;
              })}
          </tbody>
        </Table>
        {page && (
          <div className="my-3">
            <Paginate page={page} pages={pages} />
          </div>
        )}
      </Container>
    </div>
  );
};

export default ActiveDelivery;
