import React from "react";
import Users from "../components/Users";
import Ids from "../components/Ids";
import ActiveDelivery from "../components/ActiveDelivery";

const Dashboard = () => {
  return (
    <div>
      Dashboard
      <Users />
      <Ids />
      <ActiveDelivery />
    </div>
  );
};

export default Dashboard;
