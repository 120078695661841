import React from "react";
import { Col, Row, Image, Container, Card } from "react-bootstrap";
import container from "../images/container-tracking.jpg";
import customer from "../images/customer-care.jpg";
import inter from "../images/intercoastal.jpg";
import quality from "../images/quality.jpg";
import schedules from "../images/schedules.jpg";
import services from "../images/services-icon.jpg";
import { useInView } from "react-intersection-observer";

// Reusable ServiceCard component
const ServiceCard = ({ imageSrc, title, text }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <Card
      ref={ref}
      className={`service-card ${inView ? "fade-in" : ""}`}
      style={{
        width: "22rem",
        backgroundColor: "#fff",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
        padding: "0.5rem",
        border: "none",
        marginBottom: "2rem",
        opacity: inView ? 1 : 0, // Adjust opacity based on inView state
        transition: "opacity 0.5s ease-in-out", // Add a transition for smooth fade-in
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Center align the content
        textAlign: "center", // Center align the text
      }}
    >
      <Card.Body style={{ padding: "0.5rem" }}>
        <div style={{ marginBottom: "0.5rem" }}>
          <Image src={imageSrc} width={40} height={40} alt={title} />
        </div>
        <div>
          <Card.Title style={{ marginBottom: "0.3rem", fontSize: "1rem", color: "#6A6767" }}>{title}</Card.Title>
          <Card.Text style={{ fontSize: "0.8rem", color: "#6A6767" }}>{text}</Card.Text>
        </div>
      </Card.Body>
    </Card>
  );
};

const Services = () => {
  return (
    <section style={{ marginLeft: '6%', marginTop: "5%" }}>
      <Container fluid>
        <h2 style={{ color: '#184e87', marginBottom: '2rem', textAlign: 'center', fontFamily:'Cambria, Cochin, Georgia, Times, serif' }}>
          Our Guarantees and Services
        </h2>
        <Row className="services" style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Col xs={12} md={4} style={{ marginBottom: '2rem' }}>
            <ServiceCard 
              imageSrc={container}
              title="Container Tracking"
              text="SLD has been a pioneer in EDI technology. We have the ability to provide all the necessary information of your shipment from pick up to delivery."
            />
          </Col>
          <Col xs={12} md={4} style={{ marginBottom: '2rem' }}>
            <ServiceCard 
              imageSrc={customer}
              title="My SLD"
              text="A quick and easy way to track every move your container shipment makes. Register and everything you want to know about your container is there in real time! Get invoices, bills of lading, make VGM submissions."
            />
          </Col>
          <Col xs={12} md={4} style={{ marginBottom: '2rem' }}>
            <ServiceCard 
              imageSrc={inter}
              title="Intercoastal Short Sea Service"
              text="SLD's Short Sea Service keeps your cargo moving without over-the-road delays. The Short Sea Advantage provides fixed, weekly schedules throughout Europe transporting your containers or oversized cargo safely and expeditiously."
            />
          </Col>
          <Col xs={12} md={4} style={{ marginBottom: '2rem' }}>
            <ServiceCard 
              imageSrc={quality}
              title="Quality, Security, & Sustainability"
              text="SLD's commitment to sustainability and environmental awareness. We provide efficient transportation services that minimize negative environmental impact with SLD's new, greener, G4 vessels, reducing emissions per TEU by 65%."
            />
          </Col>
          <Col xs={12} md={4} style={{ marginBottom: '2rem' }}>
            <ServiceCard 
              imageSrc={schedules}
              title="Schedules"
              text="Interactive vessel schedules to and from North America and Europe. Cargo, rail and documentation cutoff times help get cargo on time to the right place."
            />
          </Col>
          <Col xs={12} md={4} style={{ marginBottom: '2rem' }}>
            <ServiceCard 
              imageSrc={services}
              title="Services: North Europe, Med, West Africa"
              text="Weekly RORO and container service."
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Services;
