import React, { useEffect, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { deleteId, generateId, getAllId } from "../actions/userActions";
import Loader from "./Loader";
import Message from "./Message";

const Users = () => {
  const dispatch = useDispatch();

  const [list, setList] = useState();

  const { loading, success, serverReply, error } = useSelector(
    (store) => store.idAllGet
  );
  const {
    loading: iLoading,
    success: iSuccess,
    serverReply: iServerReply,
    error: iError,
  } = useSelector((store) => store.idGenerate);

  const {
    loading: dLoading,
    success: dSuccess,
    // serverReply: dServerReply,
    error: dError,
  } = useSelector((store) => store.idDelete);

  useEffect(() => {
    dispatch(getAllId("no"));
  }, [dispatch, dSuccess, iSuccess]);

  useEffect(() => {
    if (success) {
      const users = serverReply?.data?.ids;

      setList(users);
    }
  }, [success, serverReply]);

  return (
    <div className="py-5 my-5">
      <h2 className="text-center">Registration Code</h2>

      <Container>
        <div className="mt-5 mb-3">
          {iError && <Message variant="danger">{iError}</Message>}
          {iServerReply && (
            <Message variant="success">{iServerReply.message}</Message>
          )}
          {iLoading ? (
            <Loader width="30px" height="30px" />
          ) : (
            <Button variant="primary" onClick={() => dispatch(generateId())}>
              Generate Registration Code
            </Button>
          )}
        </div>
        {loading && <Loader />}
        {dLoading && <Loader />}
        {error && <Message variant="danger">{error}</Message>}
        {dError && <Message variant="danger">{dError}</Message>}
        <Table striped bordered hover responsive size="sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Registration Code</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list.map((x, i) => {
                const { _id } = x;

                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{_id}</td>

                    <td>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() =>
                          window.confirm(
                            "Are you sure you want to remove this registration code?"
                          ) && dispatch(deleteId(_id))
                        }
                      >
                        Remove reg code
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

export default Users;
