import React, { useEffect, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { FaTimes, FaCheck } from "react-icons/fa";

import { useDispatch, useSelector } from "react-redux";
import { acceptUser, deleteId, getAllUser } from "../actions/userActions";
import Loader from "./Loader";
import Message from "./Message";

const Users = () => {
  const dispatch = useDispatch();

  const [list, setList] = useState(null);

  const { loading, success, serverReply, error } = useSelector(
    (store) => store.userAllGet
  );

  const {
    loading: dLoading,
    success: dSuccess,
    // serverReply: dServerReply,
    error: dError,
  } = useSelector((store) => store.idDelete);
  const {
    loading: aLoading,
    success: aSuccess,
    error: aError,
  } = useSelector((store) => store.userAccept);

  useEffect(() => {
    dispatch(getAllUser("yes"));
  }, [dispatch, aSuccess, dSuccess]);

  useEffect(() => {
    if (success) {
      const users = serverReply?.data?.ids;

      setList(users);
    }
  }, [success, serverReply]);

  const handleAccept = (id) => {
    if (window.confirm("Are you sure you want to accept this user?")) {
      dispatch(acceptUser(id, "no"));
    }
  };

  const handleAdmin = (id) => {
    if (window.confirm("Are you sure want to make this user an admin?")) {
      dispatch(acceptUser(id, "yes"));
    }
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      dispatch(deleteId(id));
    }
  };

  return (
    <div className="py-5 my-5">
      <h2 className="text-center">Users</h2>
      <Container>
        {loading && <Loader />}
        {aLoading && <Loader />}
        {dLoading && <Loader />}
        {error && <Message variant="danger">{error}</Message>}
        {aError && <Message variant="danger">{aError}</Message>}
        {dError && <Message variant="danger">{dError}</Message>}
        <Table striped bordered hover responsive size="sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Username</th>
              <th>Accepted</th>
              <th>Admin</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list.map((x, i) => {
                const { name, email, username, isAdmin, isAccepted, _id } = x;

                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{name}</td>
                    <td>{email}</td>
                    <td>{username}</td>
                    <td>
                      {isAccepted ? (
                        <FaCheck color="green" size={25} />
                      ) : (
                        <>
                          <FaTimes color="red" size={25} />{" "}
                          <Button
                            variant="primary"
                            size="sm"
                            onClick={() => handleAccept(_id)}
                          >
                            Accept
                          </Button>
                        </>
                      )}
                    </td>
                    <td>
                      {isAdmin ? (
                        <FaCheck color="green" size={25} />
                      ) : (
                        <>
                          <FaTimes color="red" size={25} />{" "}
                          <Button
                            variant="primary"
                            size="sm"
                            onClick={() => handleAdmin(_id)}
                          >
                            Make Admin
                          </Button>
                        </>
                      )}
                    </td>
                    <td>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleDelete(_id)}
                      >
                        Remove User
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

export default Users;
