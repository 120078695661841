import React from "react";
import { Spinner } from "react-bootstrap";

const Loader = ({
  animation = "border",
  color = "#272727",
  width = "70px",
  height = "70px",
  display = "block",
}) => {
  return (
    <Spinner
      animation={animation}
      role="status"
      style={{
        width,
        height,
        margin: "auto",
        display,
        color,
      }}
    >
      <span className="sr-only">Loading...</span>
    </Spinner>
  );
};

export default Loader;
