import React from "react";
import { Container } from "react-bootstrap";

const About = () => {
  return (
    <main className="py-5 my-5 about">
      <h1 className="text-center">About Us</h1>
      <Container>
        <p className="first-p">
          Swizz Lloyd Delivery Shipping Company is a global business engaged in
          the shipping and logistics sector. Present in 155 countries, SLD
          facilitates international trade between the world’s major economies,
          and among emerging markets across all continents.
        </p>
        <p>
          Founded in 1970 and headquartered in United Kingdom, since 1978, SLD
          is a privately-owned organisation driven by the Aponte family. A world
          leader in container shipping, SLD has evolved from a one vessel
          operation into a globally-respected business with a fleet of 520
          vessels and more than 70,000 staff.
        </p>
        <p>
          SLD delivers goods and services to local communities, customers and
          international business partners. With access to an integrated global
          network of road, rail and sea transport resources, the company prides
          itself on delivering global service with local knowledge.
        </p>
      </Container>
    </main>
  );
};

export default About;
