import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import Sample from '../components/Sample';
import Services from '../components/Services';
import { GET_DELIVERY_RESET } from '../constants/deliveryConstants';
import { variables } from '../data/variables';
import '../index.css';
import deliveryImage from '../images/delivery8.jpg';

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [trackingNo, setTrackingNo] = useState('');
  const [showScroll, setShowScroll] = useState(false);

  const { ref: imageRef, inView: imageInView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const { ref: textRef, inView: textInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [showScroll]);

  // Add the Tawk.to script
  useEffect(() => {
    var Tawk_API = Tawk_API || {};
    var Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement('script');
      var s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/666d57d49a809f19fb3e193f/1i0dhu65d';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []);



  return (
    <main className="top">
      <section className="hero text-center">
        <div className="texts">
          <Container className="custom-container">
            <h1>
              E-commerce is nothing
              <br />
              without logistics
            </h1>
            <p>
              <small>Send • Track • Receive</small>
            </p>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                navigate('/delivery/' + trackingNo);
              }}
            >
              <input
                type="text"
                placeholder="Enter tracking code"
                onChange={(e) => {
                  dispatch({ type: GET_DELIVERY_RESET });
                  setTrackingNo(e.target.value);
                }}
                required
              />
              <button type="submit">Search</button>
            </form>
          </Container>
        </div>
      </section>
      <section className="wedo py-3">
        <Container fluid>
          <div>
            <h2
              style={{
                color: '#6a6767',
                display: 'block',
                marginLeft: '9%',
                marginBottom: '4%',
              }}
            >
              We take the burden of <br />
              <span
                style={{ color: '#184e87', display: 'block', fontSize: '28px' }}
              >
                logistics off you.
              </span>
            </h2>
            <Row>
              <Col xs={12} md={{ span: 5, offset: 1 }}>
                <div ref={imageRef} className={`our-card pb-3 ${imageInView ? 'fade-in' : ''}`}>
                  <img
                    src={deliveryImage}
                    alt="Social Commerce"
                    className="img-fluid"
                    style={{ borderRadius: '10px' }}
                  />
                </div>
              </Col>
              <Col
                xs={12}
                md={{ span: 4, offset: 1 }}
                style={{ marginTop: '4%' }}
              >
                <div className="our-card pb-3">
                  <h3 className="p-3">Latest Technologies</h3>
                  <p className="px-3">
                    Technology and its application are fundamental at{' '}
                    {variables.siteName}. We understand the role of technology
                    in providing an exceptional customer experience. For this
                    reason, our processes, products and services are built
                    around modern technology to help us deliver your shipments
                    cheaper, safely and at the speed of light.
                  </p>
                </div>
              </Col>
              <div ref={textRef} className={`blue ${textInView ? 'fade-in' : ''}`}>
                <Col
                  xs={12}
                  md={{ span: 4, offset: 1 }}
                  style={{ marginTop: '3%' }}
                >
                  <div className="our-card pb-3">
                    <h3 className="p-3">Social Commerce</h3>
                    <p className="px-3">
                      Pivotal to successful social commerce transactions is the
                      ability for the end-user to receive items where and when
                      needed. With an unmatched route network spanning urban and
                      rural communalities, {variables.siteName} is the solution to
                      efficient last-mile delivery. As a merchant, you only have
                      to think about selling your products while Swizz Lloyd
                      Delivery delivers to your customers worldwide.
                    </p>
                  </div>
                </Col>
                <Col
                  xs={12}
                  md={{ span: 4, offset: 1 }}
                  style={{ marginTop: '3%' }}
                >
                  <div className="our-card pb-3">
                    <h3 className="p-3">Guaranteed Delivery</h3>
                    <p className="px-3">
                      We are conscious of the
                      relationship between time and money and
                      utilize our resources optimally to meet your delivery needs.
                      Our service commitment to you is hassle-free delivery. We
                      are large enough to efficiently handle deliveries for large
                      corporations yet small enough to deploy innovative delivery
                      solutions that help small businesses.
                    </p>
                  </div>
                </Col>
              </div>
            </Row>
          </div>
        </Container>
      </section>
      <Services />
      <Sample />
      <button
        className="scrollTop"
        onClick={scrollTop}
        style={{ display: showScroll ? 'flex' : 'none' }}
      >
        &#8679;
      </button>
    </main>
  );
};

export default Home;
