import {
  ADD_IMAGE_FAIL,
  ADD_IMAGE_REQUEST,
  ADD_IMAGE_RESET,
  ADD_IMAGE_SUCCESS,
  CREATE_DELIVERY_FAIL,
  CREATE_DELIVERY_REQUEST,
  CREATE_DELIVERY_RESET,
  CREATE_DELIVERY_SUCCESS,
  DELETE_ACTIVE_DELIVERY_FAIL,
  DELETE_ACTIVE_DELIVERY_REQUEST,
  DELETE_ACTIVE_DELIVERY_SUCCESS,
  GET_ACTIVE_DELIVERY_FAIL,
  GET_ACTIVE_DELIVERY_REQUEST,
  GET_ACTIVE_DELIVERY_SUCCESS,
  GET_DELIVERY_FAIL,
  GET_DELIVERY_REQUEST,
  GET_DELIVERY_RESET,
  GET_DELIVERY_SUCCESS,
  MODIFY_DELIVERY_LOCATION_FAIL,
  MODIFY_DELIVERY_LOCATION_REQUEST,
  MODIFY_DELIVERY_LOCATION_SUCCESS,
  MODIFY_DELIVERY_STATUS_FAIL,
  MODIFY_DELIVERY_STATUS_REQUEST,
  MODIFY_DELIVERY_STATUS_SUCCESS,
} from "../constants/deliveryConstants";

export const getActiveDeliveryReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ACTIVE_DELIVERY_REQUEST:
      return { loading: true };
    case GET_ACTIVE_DELIVERY_SUCCESS:
      return { loading: false, serverReply: action.payload, success: true };
    case GET_ACTIVE_DELIVERY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteDeliveryReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_ACTIVE_DELIVERY_REQUEST:
      return { loading: true };
    case DELETE_ACTIVE_DELIVERY_SUCCESS:
      return { loading: false, serverReply: action.payload, success: true };
    case DELETE_ACTIVE_DELIVERY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getDeliveryReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_DELIVERY_REQUEST:
      return { loading: true };
    case GET_DELIVERY_SUCCESS:
      return { loading: false, serverReply: action.payload, success: true };
    case GET_DELIVERY_FAIL:
      return { loading: false, error: action.payload };
    case GET_DELIVERY_RESET:
      return {};
    default:
      return state;
  }
};

export const createDeliveryReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_DELIVERY_REQUEST:
      return { loading: true };
    case CREATE_DELIVERY_SUCCESS:
      return { loading: false, serverReply: action.payload, success: true };
    case CREATE_DELIVERY_FAIL:
      return { loading: false, error: action.payload };
    case CREATE_DELIVERY_RESET:
      return {};
    default:
      return state;
  }
};

export const addImageReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_IMAGE_REQUEST:
      return { loading: true };
    case ADD_IMAGE_SUCCESS:
      return { loading: false, serverReply: action.payload, success: true };
    case ADD_IMAGE_FAIL:
      return { loading: false, error: action.payload };
    case ADD_IMAGE_RESET:
      return {};
    default:
      return state;
  }
};

export const modifyDeliveryStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case MODIFY_DELIVERY_STATUS_REQUEST:
      return { loading: true };
    case MODIFY_DELIVERY_STATUS_SUCCESS:
      return { loading: false, serverReply: action.payload, success: true };
    case MODIFY_DELIVERY_STATUS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const modifyDeliveryLocationReducer = (state = {}, action) => {
  switch (action.type) {
    case MODIFY_DELIVERY_LOCATION_REQUEST:
      return { loading: true };
    case MODIFY_DELIVERY_LOCATION_SUCCESS:
      return { loading: false, serverReply: action.payload, success: true };
    case MODIFY_DELIVERY_LOCATION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
