import React, { useEffect } from 'react';
import {
  Container,
  Image,
  Nav,
  Navbar,
  NavDropdown,
  Button,
  ButtonGroup,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { logout } from '../actions/userActions';
import { variables } from '../data/variables';
import useAdmin from './useAdmin';
import {
  FaHome,
  FaInfoCircle,
  FaFileAlt,
  FaEnvelope,
  FaInstagram,
  FaTwitter,
  FaFacebook,
  FaWhatsapp,
} from 'react-icons/fa';

const Layout = ({ children }) => {
  const dispatch = useDispatch();

  const { username, admin } = useAdmin();

  const { userInfo } = useSelector((state) => state.userLogin);

  // Google Translate
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
      },
      'google_translate_element'
    );
  };

  // google translate
  useEffect(() => {
    var addScript = document.createElement('script');
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);
  // end of google translate

  return (
    <>
      <Navbar
        bg="dark"
        variant="dark"
        expand="lg"
        collapseOnSelect
        fixed="top"
        id="rem-pad"
        className="bg-black"
      >
        <Container>
          <Navbar.Brand>
            <LinkContainer to="/">
              <Image
                src={variables.logo}
                width={176.2}
                height={62.6}
                style={{
                  objectFit: 'cover',
                  cursor: 'pointer',
                }}
              />
            </LinkContainer>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <LinkContainer to="/">
                <Nav.Link>Home</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/about">
                <Nav.Link>About</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/terms">
                <Nav.Link>Terms</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/contact">
                <Nav.Link>Contact</Nav.Link>
              </LinkContainer>
              <NavDropdown title="Offices" id="basic-nav-dropdown">
                <NavDropdown.Item href="/contact/#uk">
                  Worldwide
                </NavDropdown.Item>

                <NavDropdown.Divider />
              </NavDropdown>
            </Nav>
            <Nav>
              {!userInfo ? (
                <LinkContainer to="/auth">
                  <Button variant="primary" className="ms-2">
                    Login / Register
                  </Button>
                </LinkContainer>
              ) : (
                <ButtonGroup>
                  <Button variant="secondary">
                    <LinkContainer to="/profile">
                      <Nav.Link>Profile - {username && username}</Nav.Link>
                    </LinkContainer>
                  </Button>
                  {admin && (
                    <Button variant="secondary">
                      <LinkContainer to="/dashboard">
                        <Nav.Link>Dashboard</Nav.Link>
                      </LinkContainer>
                    </Button>
                  )}
                  <Button
                    size="sm"
                    onClick={() => dispatch(logout())}
                    variant="danger"
                  >
                    Logout
                  </Button>
                </ButtonGroup>
              )}
              <div id="google_translate_element"></div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {children}
      <footer className='text-footer' >
        <Container>
          <div className="links">
            <div>
              <LinkContainer to="/">
                <Image
                  src={variables.logo}
                  width={300}
                  height="auto"
                  style={{ objectFit: 'cover', cursor: 'pointer' }}
                  alt="Logo"
                />
              </LinkContainer>
              <h5 style={{color:'white'}}>Your convinience is our concern<br></br>
              we provide the best at all cost</h5>
            </div>
          
            <div style={{ marginRight: '15%' }}>
              <h3 className="pt-2" style={{ color: 'white' }}>
                Company
              </h3>
              <LinkContainer to="/">
                <Nav.Link style={{ color: 'white' }}>
                  <FaHome /> Home
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/about">
                <Nav.Link style={{ color: 'white' }}>
                  <FaInfoCircle /> About
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/terms">
                <Nav.Link style={{ color: 'white' }}>
                  <FaFileAlt /> Terms
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/contact">
                <Nav.Link style={{ color: 'white' }}>
                  <FaEnvelope /> Contact
                </Nav.Link>
              </LinkContainer>

            </div>
            <div>
              <h3 className="pt-2" style={{ color: 'white' }}>
                Reach us:
              </h3>
              
              <div className="social-icons">
                <a
                  href="https://www.instagram.com/swizzlloydd?igsh=ZHEwcmM1OWc2ZDR6&utm_source=qr"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "white", fontWeight: "bold", marginRight: "10px" }}
                >
                  <FaInstagram />
                </a>
                <a
                  href="https://x.com/LloydSwizz"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "white", fontWeight: "bold", marginRight: "10px" }}
                >
                  <FaTwitter />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=61562522092715"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "white", fontWeight: "50%", marginRight: "10px" }}
                >
                  <FaFacebook />
                </a>
                <a
                  href='https://wa.me/14706730284'
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "white", fontWeight: "bold", marginRight: "10px" }}
                >
                  <FaWhatsapp />
                </a>
              </div>
            </div>
          </div>
          <p className="text-center py-2" style={{ color: 'white' }}>
            &copy; {new Date().getFullYear()} {variables.siteName}
          </p>
        </Container>
      </footer>
    </>
  );
};

export default Layout;
