import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import { userResetPassword } from "../actions/userActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
// import { USER_RESET_PASSWORD_RESET } from "../constants/userConstants";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const params = useParams();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const { loading, success, serverReply, error } = useSelector(
    (store) => store.passwordResetUser
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match.");
    } else {
      setPasswordError("");
      dispatch(userResetPassword(password, params.token));
    }
  };

  useEffect(() => {
    let timeOut;
    if (success) {
      timeOut = setTimeout(() => {
        if (typeof window !== "undefined") {
          router("/user/auth");
        }
      }, 3000);
    }
    return () => clearTimeout(timeOut);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);
  return (
    <main className="big-mb">
      <h1 className="main-header text-center py-5">Reset Password</h1>
      <div className="form-container py-3">
        <Form onSubmit={handleSubmit}>
          {success && (
            <Message variant="success">{serverReply.message}</Message>
          )}
          {error && <Message variant="danger">{error}</Message>}
          {passwordError && <Message variant="danger">{passwordError}</Message>}
          {loading && <Loader />}
          <Form.Group>
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              onChange={(e) => {
                setPassword(e.target.value);
                // dispatch({ type: USER_RESET_PASSWORD_RESET });
              }}
              autoComplete="false"
              placeholder="Enter a secure password"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                // dispatch({ type: RESET_PASSWORD_RESET });
              }}
              autoComplete="false"
              placeholder="Enter confirm password"
            />
          </Form.Group>
          <Button type="submit" size="lg">
            Submit
          </Button>
        </Form>
      </div>
    </main>
  );
};

export default ResetPassword;
