import axios from "axios";
import { variables } from "../data/variables";
import {
  ADD_IMAGE_FAIL,
  ADD_IMAGE_REQUEST,
  ADD_IMAGE_SUCCESS,
  CREATE_DELIVERY_FAIL,
  CREATE_DELIVERY_REQUEST,
  CREATE_DELIVERY_SUCCESS,
  DELETE_ACTIVE_DELIVERY_FAIL,
  DELETE_ACTIVE_DELIVERY_REQUEST,
  DELETE_ACTIVE_DELIVERY_SUCCESS,
  GET_ACTIVE_DELIVERY_FAIL,
  GET_ACTIVE_DELIVERY_REQUEST,
  GET_ACTIVE_DELIVERY_SUCCESS,
  GET_DELIVERY_FAIL,
  GET_DELIVERY_REQUEST,
  GET_DELIVERY_SUCCESS,
  MODIFY_DELIVERY_LOCATION_FAIL,
  MODIFY_DELIVERY_LOCATION_REQUEST,
  MODIFY_DELIVERY_LOCATION_SUCCESS,
  MODIFY_DELIVERY_STATUS_FAIL,
  MODIFY_DELIVERY_STATUS_REQUEST,
  MODIFY_DELIVERY_STATUS_SUCCESS,
} from "../constants/deliveryConstants";

export const getActiveDelivery =
  (pageNumber = 1) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_ACTIVE_DELIVERY_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      let config;

      if (userInfo) {
        config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
      } else {
        config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
      }

      const { data } = await axios.get(
        `${variables.backendLink}/delivery/all-deliveries/?pageNumber=${pageNumber}`,
        config
      );
      dispatch({
        type: GET_ACTIVE_DELIVERY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ACTIVE_DELIVERY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteDelivery = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_ACTIVE_DELIVERY_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    let config;

    if (userInfo) {
      config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
    } else {
      config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
    }

    const { data } = await axios.delete(
      `${variables.backendLink}/delivery/delete/${id}`,
      config
    );
    dispatch({
      type: DELETE_ACTIVE_DELIVERY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_ACTIVE_DELIVERY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getDelivery = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DELIVERY_REQUEST,
    });

    let config;

    config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${variables.backendLink}/delivery/single/${id}`,
      config
    );
    dispatch({
      type: GET_DELIVERY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_DELIVERY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createDelivery =
  (
    productName,
    deliveryTime,
    receiver,
    size,
    sender,
    origin,
    pickupDate,
    transportMode,
    images
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: CREATE_DELIVERY_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const fData = new FormData();

      fData.append("productName", productName);
      fData.append("deliveryTime", deliveryTime);
      fData.append("receiverName", receiver.name);
      fData.append("receiverAddress", receiver.address);
      fData.append("receiverEmail", receiver.email);
      fData.append("size", size);
      fData.append("senderName", sender.name);
      fData.append("senderAddress", sender.address);
      fData.append("senderEmail", sender.email);
      fData.append("origin", origin);
      fData.append("pickupDate", pickupDate);
      fData.append("transportMode", transportMode);

      images.forEach((image, index) => {
        fData.append("image", image);
      });

      const { data } = await axios.post(
        `${variables.backendLink}/delivery/create`,
        fData,
        config
      );
      dispatch({
        type: CREATE_DELIVERY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_DELIVERY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addImage = (id, images) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_IMAGE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const fData = new FormData();

    images.forEach((image, index) => {
      fData.append("image", image);
    });

    const { data } = await axios.put(
      `${variables.backendLink}/delivery/add-images/${id}`,
      fData,
      config
    );
    dispatch({
      type: ADD_IMAGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADD_IMAGE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const modifyDeliveryStatus =
  (id, status, message) => async (dispatch, getState) => {
    try {
      dispatch({
        type: MODIFY_DELIVERY_STATUS_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `${variables.backendLink}/delivery/single/${id}`,
        { status, message },
        config
      );
      dispatch({
        type: MODIFY_DELIVERY_STATUS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MODIFY_DELIVERY_STATUS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const modifyDeliveryLocation =
  (newLocation, id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: MODIFY_DELIVERY_LOCATION_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `${variables.backendLink}/delivery/single/location/${id}`,
        { newLocation },
        config
      );
      dispatch({
        type: MODIFY_DELIVERY_LOCATION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MODIFY_DELIVERY_LOCATION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
