import React, { useState } from "react";
import { deleteDelivery } from "../actions/deliveryActions";

import { FaTimes, FaCheck } from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import ModifyStatusForm from "./ModifyStatusForm";
import ModifyLocationForm from "./ModifyLocationForm";

const ActiveDeliveryTr = ({ x, i }) => {
  const dispatch = useDispatch();

  const [showForm, setShowForm] = useState(false);
  const [showLocationForm, setShowLocationForm] = useState(false);

  return (
    <tr key={x?._id}>
      <td>{i + 1}</td>
      <td>SLD-{x?._id}</td>
      <td>{x?.user?.username}</td>
      <td>{new Date(x?.deliveryTime?.start).toDateString()}</td>
      <td>{new Date(x?.deliveryTime?.end).toDateString()}</td>
      <td>{x?.receiver?.name}</td>

      <td>{x?.productName && x?.productName}</td>
      <td>
        {x?.delivered ? (
          <FaCheck color="green" size={25} />
        ) : (
          <FaTimes color="red" size={25} />
        )}
      </td>
      <td style={{ textTransform: "capitalize" }}>
        {x?.deliveryStatus?.status.replace(/[-]/gi, " ")}
      </td>
      <td colSpan={2}>
        {showForm ? (
          <ModifyStatusForm _id={x?._id} setShowForm={setShowForm} />
        ) : (
          <Button
            variant="secondary"
            size="sm"
            onClick={() => setShowForm(true)}
          >
            Modify
          </Button>
        )}
      </td>
      <td>{x?.deliveryLocation?.location}</td>
      <td>
        {showLocationForm ? (
          <ModifyLocationForm
            _id={x?._id}
            setShowLocationForm={setShowLocationForm}
          />
        ) : (
          <Button
            variant="secondary"
            size="sm"
            onClick={() => setShowLocationForm(true)}
          >
            Modify Location
          </Button>
        )}
      </td>

      <td>{x?.receiver?.address}</td>
      <td>
        <LinkContainer to={"/delivery/SLD-" + x?._id}>
          <Button variant="primary" size="sm">
            View
          </Button>
        </LinkContainer>
      </td>
      <td>
        <Button
          variant="danger"
          onClick={() =>
            window.confirm("Are you sure you want to delete this delivery?") &&
            dispatch(deleteDelivery(x?._id))
          }
          size="sm"
        >
          Delete
        </Button>
      </td>
    </tr>
  );
};

export default ActiveDeliveryTr;
