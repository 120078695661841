import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {
  userRegisterReducer,
  userLoginReducer,
  generateIdReducer,
  getAllIdReducer,
  deleteIdReducer,
  getUserProfileReducer,
  updateProfileReducer,
  userForgotPasswordReducer,
  userResetPasswordReducer,
  getAllUserReducer,
  acceptUserReducer,
} from "./reducers/userReducers";

import {
  getActiveDeliveryReducer,
  deleteDeliveryReducer,
  getDeliveryReducer,
  createDeliveryReducer,
  addImageReducer,
  modifyDeliveryStatusReducer,
  modifyDeliveryLocationReducer,
} from "./reducers/deliveryReducers";

const reducer = combineReducers({
  registerUser: userRegisterReducer,
  userLogin: userLoginReducer,
  idGenerate: generateIdReducer,
  idAllGet: getAllIdReducer,
  userAllGet: getAllUserReducer,
  idDelete: deleteIdReducer,
  profileUserGet: getUserProfileReducer,
  profileUpdate: updateProfileReducer,
  passwordForgotUser: userForgotPasswordReducer,
  passwordResetUser: userResetPasswordReducer,
  userAccept: acceptUserReducer,

  // Deliver Reducers
  activeDeliveryGet: getActiveDeliveryReducer,
  deliveryDelete: deleteDeliveryReducer,
  deliveryGet: getDeliveryReducer,
  deliveryCreate: createDeliveryReducer,
  imageAdd: addImageReducer,
  modifyDeliveryStatus: modifyDeliveryStatusReducer,
  modifyDeliveryLocation: modifyDeliveryLocationReducer,
});

// Local storage matters
const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

// initial state
const initialState = {
  userLogin: {
    userInfo: userInfoFromStorage,
  },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
