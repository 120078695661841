import React, { useEffect, useState } from "react";
import { Card, Col, Container, Image, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getDelivery } from "../actions/deliveryActions";
import { useParams, useNavigate } from "react-router-dom";
import { FaArrowCircleLeft } from "react-icons/fa";
import Loader from "../components/Loader";
import Message from "../components/Message";

const Delivery = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [productName, setProductName] = useState("");
  const [deliveryTime, setDeliveryTime] = useState("");
  const [receiverName, setReceiverName] = useState("");
  const [receiverAddress, setReceiverAddress] = useState("");
  const [receiverEmail, setReceiverEmail] = useState("");
  const [size, setSize] = useState(0);
  const [senderName, setSenderName] = useState("");
  const [senderAddress, setSenderAddress] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [origin, setOrigin] = useState("");
  const [pickupDate, setPickupDate] = useState("");
  const [transportMode, setTransportMode] = useState("");
  const [images, setImages] = useState([]);
  const [comment, setComment] = useState("");
  const [status, setStatus] = useState("");
  const [location, setLocation] = useState("");
  const [trackingNo, setTrackingNo] = useState("");
  const [updateAt, setUpdateAt] = useState("");

  // const [] =

  const { loading, success, serverReply, error } = useSelector(
    (state) => state.deliveryGet
  );

  useEffect(() => {
    dispatch(getDelivery(params.id));
  }, [dispatch, params]);

  useEffect(() => {
    if (success) {
      const d = serverReply?.data?.delivery;

      setSenderName(d?.sender?.name);
      setSenderAddress(d?.sender?.address);
      setSenderEmail(d?.sender?.email);

      setReceiverName(d?.receiver?.name);
      setReceiverAddress(d?.receiver?.address);
      setReceiverEmail(d?.receiver?.email);

      setOrigin(d?.origin);
      setProductName(d?.productName);
      setSize(d?.size);

      setDeliveryTime(d?.deliveryTime?.start);
      setPickupDate(d?.pickupDate);
      setUpdateAt(d?.updatedAt);
      setLocation(d?.deliveryLocation?.location);

      // setComment(
      //   d?.pickupDate && new Date(pickupDate) < new Date()
      //     ? "Product has Arrived successfully"
      //     : "Product hasn't arrived."
      // );

      setComment(
        d?.deliveryStatus?.message
          ? d?.deliveryStatus?.message
          : "The product is out for delivery"
      );

      setStatus(d?.deliveryStatus?.status);

      setTransportMode(d?.transportMode);
      setImages(d?.productImages);
      setTrackingNo(d?._id);
    }
  }, [success, serverReply, pickupDate]);

  return (
    <main className="py-5 my-5">
      <Container className="border py-5 my-5">
        {loading && <Loader />}
        {error && <Message variant="danger">{error}</Message>}

        <p>
          <FaArrowCircleLeft
            size={35}
            title="Go back"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
        </p>
        <h1 className="pb-3">Tracking No: SLD-{trackingNo}</h1>
        <Row>
          <Col xs={12} md={6}>
            <Card>
              <Card.Header>
                <Card.Title>Shipper Address</Card.Title>
              </Card.Header>
              <Card.Body>
                <Card.Text>Name: {senderName}</Card.Text>
                <Card.Text>Address: {senderAddress}</Card.Text>
                <Card.Text>Email: {senderEmail}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card>
              <Card.Header>
                <Card.Title>Receiver Address</Card.Title>
              </Card.Header>
              <Card.Body>
                <Card.Text>Name: {receiverName}</Card.Text>
                <Card.Text>Address: {receiverAddress}</Card.Text>
                <Card.Text>Email: {receiverEmail}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Table responsive size="sm" bordered className="my-3">
          <thead>
            <tr>
              <th>Origin</th>
              <th>Product Name</th>
              <th>Product weight</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> {origin}</td>
              <td>{productName}</td>
              <td>{size}kg</td>
            </tr>
          </tbody>
        </Table>
        <Table responsive size="sm" bordered className="my-3">
          <thead>
            <tr>
              <th>Departure Time </th>
              <th>Delivery Date</th>
              <th>Status</th>
              <th>Shipment Location</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{deliveryTime}</td>
              <td>{pickupDate}</td>
              <td style={{ textTransform: "capitalize" }}>
                {status.replace(/[-]/gi, " ")}
              </td>
              <td>{location}</td>
            </tr>
          </tbody>
        </Table>
        <Table responsive size="sm" bordered className="my-3">
          <thead>
            <tr>
              <th>Comment</th>
              <th>Type of shipment</th>
              <th>Receiver Address</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {comment} <br />
                updated: {new Date(updateAt).toLocaleDateString()}
              </td>
              <td>{transportMode}</td>
              <td>{receiverAddress}</td>
            </tr>
          </tbody>
        </Table>
        <div>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
            {images &&
              images.map((i, index) => (
                <Image
                  key={index}
                  src={i?.url}
                  width={300}
                  height={300}
                  style={{ objectFit: "cover" }}
                />
              ))}
          </div>
        </div>
      </Container>
    </main>
  );
};

export default Delivery;
