import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { userForgotPassword } from "../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Forget = () => {
  const dispatch = useDispatch();
  const router = useNavigate();

  const [email, setEmail] = useState("");

  const { loading, success, serverReply, error } = useSelector(
    (store) => store.passwordForgotUser
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(userForgotPassword(email));
  };

  return (
    <main className="big-mb py-5">
      <h1 className="main-header text-center py-5 my-5 ">Forgot Password?</h1>
      <div className="form-container py-3">
        <Form onSubmit={handleSubmit}>
          {success && (
            <Message variant="success">{serverReply.message}</Message>
          )}
          {error && <Message variant="danger">{error}</Message>}

          {loading && <Loader />}
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder="Enter your email"
              required
            />
          </Form.Group>

          <Button type="submit" size="lg">
            Submit
          </Button>
          <>
            <span className="d-block my-2">
              Remember password?{" "}
              <Button size="sm" onClick={() => router("/user/auth")}>
                Login
              </Button>{" "}
            </span>
          </>
        </Form>
      </div>
    </main>
  );
};

export default Forget;
