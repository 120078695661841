import { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { modifyDeliveryLocation } from "../actions/deliveryActions";
import { useDispatch, useSelector } from "react-redux";
import { FaTimes } from "react-icons/fa";
import Loader from "./Loader";

const ModifyStatusForm = ({ _id, setShowLocationForm }) => {
  const dispatch = useDispatch();
  const [location, setLocation] = useState("");

  const { loading: loadingModifyLocation } = useSelector(
    (state) => state.modifyDeliveryLocation
  );

  return (
    <>
      {loadingModifyLocation ? (
        <Loader color="black" height="30px" width="30px" />
      ) : (
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            dispatch(modifyDeliveryLocation(location, _id));
          }}
        >
          <FaTimes
            size={20}
            onClick={() => setShowLocationForm(false)}
            color="crimson"
            className="mb-2 "
            title="close"
            style={{ cursor: "pointer", float: "right" }}
          />
          <Form.Group controlId="location">
            <Form.Control
              placeholder="Enter location *"
              onChange={(e) => setLocation(e.target.value)}
              required
            />
          </Form.Group>
          <Button
            size="sm"
            type="submit"
            className="my-2 ml-5 border"
            style={{ marginLeft: "5rem" }}
          >
            Modify Location
          </Button>
        </Form>
      )}
    </>
  );
};

export default ModifyStatusForm;
