import React, { useEffect, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile, updateProfile } from "../actions/userActions";
import Loader from "../components/Loader";
import Message from "../components/Message";

const Profile = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verified, setVerified] = useState(false);

  const { loading, serverReply } = useSelector((store) => store.profileUserGet);
  const {
    loading: uLoading,
    success: uSuccess,
    serverReply: uServerReply,
    error: uError,
  } = useSelector((store) => store.profileUpdate);

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(updateProfile(email, name, password));
  };

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  useEffect(() => {
    if (uServerReply?.data) {
      const { name, username, email } = uServerReply?.data;
      setEmail(email);
      setName(name);
      setUsername(username);
    }
  }, [uServerReply]);

  useEffect(() => {
    if (serverReply) {
      const { name, username, email } = serverReply?.data?.user;

      setEmail(email);
      setName(name);
      setUsername(username);
    }
  }, [serverReply]);

  useEffect(() => {}, []);

  useEffect(() => {
    let poster;
    if (typeof window !== "undefined") {
      poster = localStorage.getItem("userInfo");

      if (poster) {
        setVerified(true);
      } else {
        document.location.href = "/";
      }
    }
  }, []);

  if (!verified) {
    return (
      <Message variant="danger">
        You do not have access to view this page
      </Message>
    );
  } else {
    return (
      <main className="my-5 py-5">
        <Container>
          <h1 className="main-header text-center py-5">Your Profile</h1>
          {loading ? (
            <Loader />
          ) : (
            <div className="form-container py-3">
              <Form onSubmit={handleSubmit}>
                <small className="text-muted mt-5 d-block">
                  ** Some fields have been locked
                </small>

                {uLoading && <Loader />}
                {uSuccess && (
                  <Message variant="success">{uServerReply.message}</Message>
                )}
                {uError && <Message variant="danger">{uError}</Message>}
                <Form.Group>
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="enter full name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="enter email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicUsername">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    className="about-form"
                    placeholder="enter username"
                    value={username}
                    readOnly
                  />
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="change password"
                    onChange={(e) => setPassword(e.target.value)}
                    minLength={6}
                  />
                </Form.Group>

                <Button size="lg" type="submit" className="my-3">
                  Update
                </Button>
              </Form>
            </div>
          )}
        </Container>
      </main>
    );
  }
};

export default Profile;
