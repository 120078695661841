import {
  GENERATE_ID_REQUEST,
  GENERATE_ID_SUCCESS,
  GENERATE_ID_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_LOGOUT,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_FORGOT_PASSWORD_FAIL,
  GET_ALL_ID_REQUEST,
  GET_ALL_ID_SUCCESS,
  GET_ALL_ID_FAIL,
  DELETE_ID_REQUEST,
  DELETE_ID_SUCCESS,
  DELETE_ID_FAIL,
  GET_SINGLE_USER_REQUEST,
  GET_SINGLE_USER_SUCCESS,
  GET_SINGLE_USER_FAIL,
  UPDATE_USER_PROFILE_REQUEST,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAIL,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAIL,
  ACCEPT_USER_REQUEST,
  ACCEPT_USER_SUCCESS,
  ACCEPT_USER_FAIL,
} from "../constants/userConstants";

export const generateIdReducer = (state = {}, action) => {
  switch (action.type) {
    case GENERATE_ID_REQUEST:
      return { loading: true };
    case GENERATE_ID_SUCCESS:
      return { loading: false, serverReply: action.payload, success: true };
    case GENERATE_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };
    case USER_REGISTER_FAIL:
      return { loading: false, success: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload, success: true };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userForgotPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_FORGOT_PASSWORD_REQUEST:
      return { loading: true };
    case USER_FORGOT_PASSWORD_SUCCESS:
      return { loading: false, success: true, serverReply: action.payload };
    case USER_FORGOT_PASSWORD_FAIL:
      return { loading: false, success: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userResetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_RESET_PASSWORD_REQUEST:
      return { loading: true };
    case USER_RESET_PASSWORD_SUCCESS:
      return { loading: false, success: true, serverReply: action.payload };
    case USER_RESET_PASSWORD_FAIL:
      return { loading: false, success: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const getAllIdReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_ID_REQUEST:
      return { loading: true };
    case GET_ALL_ID_SUCCESS:
      return { loading: false, success: true, serverReply: action.payload };
    case GET_ALL_ID_FAIL:
      return { loading: false, success: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const getAllUserReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_USERS_REQUEST:
      return { loading: true };
    case GET_ALL_USERS_SUCCESS:
      return { loading: false, success: true, serverReply: action.payload };
    case GET_ALL_USERS_FAIL:
      return { loading: false, success: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const deleteIdReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_ID_REQUEST:
      return { loading: true };
    case DELETE_ID_SUCCESS:
      return { loading: false, success: true, serverReply: action.payload };
    case DELETE_ID_FAIL:
      return { loading: false, success: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const getUserProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SINGLE_USER_REQUEST:
      return { loading: true };
    case GET_SINGLE_USER_SUCCESS:
      return { loading: false, success: true, serverReply: action.payload };
    case GET_SINGLE_USER_FAIL:
      return { loading: false, success: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const updateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_USER_PROFILE_REQUEST:
      return { loading: true };
    case UPDATE_USER_PROFILE_SUCCESS:
      return { loading: false, success: true, serverReply: action.payload };
    case UPDATE_USER_PROFILE_FAIL:
      return { loading: false, success: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};
export const acceptUserReducer = (state = {}, action) => {
  switch (action.type) {
    case ACCEPT_USER_REQUEST:
      return { loading: true };
    case ACCEPT_USER_SUCCESS:
      return { loading: false, success: true, serverReply: action.payload };
    case ACCEPT_USER_FAIL:
      return { loading: false, success: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};
