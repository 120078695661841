import React from "react";
import { Container } from "react-bootstrap";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../index.css";

import d1 from "../images/delivery1.jpg";
import d2 from "../images/delivery2.jpg";
import d3 from "../images/delivery3.jpg";
import d4 from "../images/delivery4.jpg";
import d5 from "../images/delivery5.jpg";
import d6 from "../images/delivery6.jpg";
import d7 from "../images/delivery7.jpg";
import d8 from "../images/delivery8.jpg";
import d15 from "../images/delivery15.jpg";

const images = [
  d15,
  d6,
  d7,
  d8,
  d1,
  d2,
  d3,
  d4,
  d5,
];

const Sample = () => {
  const pairedImages = [];
  for (let i = 0; i < images.length; i += 3) {
    pairedImages.push(images.slice(i, i + 3));
  }

  return (
    <Container fluid style={{ marginBottom: '6rem' }}>
      <div className="double-line"></div>
      <h2 className="py-5 text-center sample-p" style={{ color: "#6a6767" }}>
        We Take pride in what <span style={{ color: "#184e87", fontFamily:'Cambria, Cochin, Georgia, Times, serif'}}>We Do</span>
      </h2>
      <div className="samples">
        <Carousel style={{color:'blue'}}
          showArrows={false}
          showStatus={false}
          showIndicators={true}
          showThumbs={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={2000} // Change interval as needed (in milliseconds)
          stopOnHover={true}
          emulateTouch={true}
          dynamicHeight={false}
        >
          {pairedImages.map((pair, index) => (
            <div key={index} className="slide">
              <div className="image-pair">
                {pair.map((image, idx) => (
                  <div key={idx} className="image-container">
                    <img
                      src={image}
                      alt={`Slide ${index * 2 + idx}`}
                      style={{
                        width: "100%",
                        height: "350px",
                        objectFit: "cover",
                        borderRadius: "30px",
                       
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </Container>
  );
};

export default Sample;
