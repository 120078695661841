export const GENERATE_ID_REQUEST = "GENERATE_ID_REQUEST";
export const GENERATE_ID_SUCCESS = "GENERATE_ID_SUCCESS";
export const GENERATE_ID_FAIL = "GENERATE_ID_FAIL";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_FORGOT_PASSWORD_REQUEST = "USER_FORGOT_PASSWORD_REQUEST";
export const USER_FORGOT_PASSWORD_SUCCESS = "USER_FORGOT_PASSWORD_SUCCESS";
export const USER_FORGOT_PASSWORD_FAIL = "USER_FORGOT_PASSWORD_FAIL";

export const USER_RESET_PASSWORD_REQUEST = "USER_RESET_PASSWORD_REQUEST";
export const USER_RESET_PASSWORD_SUCCESS = "USER_RESET_PASSWORD_SUCCESS";
export const USER_RESET_PASSWORD_FAIL = "USER_RESET_PASSWORD_FAIL";
export const USER_RESET_PASSWORD_RESET = "USER_RESET_PASSWORD_RESET";

export const GET_ALL_ID_REQUEST = "GET_ALL_ID_REQUEST";
export const GET_ALL_ID_SUCCESS = "GET_ALL_ID_SUCCESS";
export const GET_ALL_ID_FAIL = "GET_ALL_ID_FAIL";

export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL";

export const DELETE_ID_REQUEST = "DELETE_ID_REQUEST";
export const DELETE_ID_SUCCESS = "DELETE_ID_SUCCESS";
export const DELETE_ID_FAIL = "DELETE_ID_FAIL";

export const GET_SINGLE_USER_REQUEST = "GET_SINGLE_USER_REQUEST";
export const GET_SINGLE_USER_SUCCESS = "GET_SINGLE_USER_SUCCESS";
export const GET_SINGLE_USER_FAIL = "GET_SINGLE_USER_FAIL";

export const ACCEPT_USER_REQUEST = "ACCEPT_USER_REQUEST";
export const ACCEPT_USER_SUCCESS = "ACCEPT_USER_SUCCESS";
export const ACCEPT_USER_FAIL = "ACCEPT_USER_FAIL";

export const UPDATE_USER_PROFILE_REQUEST = "UPDATE_USER_PROFILE_REQUEST";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAIL = "UPDATE_USER_PROFILE_FAIL";
export const UPDATE_USER_PROFILE_RESET = "UPDATE_USER_PROFILE_RESET";
