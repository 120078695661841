import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../actions/userActions";
import { USER_LOGOUT } from "../constants/userConstants";

const useAdmin = () => {
  const dispatch = useDispatch();
  const [admin, setAdmin] = useState(false);

  const [username, setUsername] = useState("");

  const { serverReply, error } = useSelector((store) => store.profileUserGet);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      dispatch({ type: USER_LOGOUT });
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (serverReply) {
      const { isAdmin, username } = serverReply?.data?.user;

      setAdmin(isAdmin);
      setUsername(username);
    }
  }, [serverReply]);
  return { admin, username };
};

export default useAdmin;
