import React, { useState, useEffect } from "react";
import { Container, Form, Button, Image } from "react-bootstrap";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { login, register as registerAction } from "../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../images/logo.png"; // Make sure to update the path to your logo

const Auth = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const [regCode, setRegCode] = useState("");
  const [register, setRegister] = useState(false);
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);

  const {
    loading: loadingReg,
    success,
    userInfo,
    error: errorReg,
  } = useSelector((store) => store.registerUser);
  const {
    loading: loadingLog,
    success: successLog,
    userInfo: posterInfoLog,
    error: errorLog,
  } = useSelector((store) => store.userLogin);

  const setValue = () => {
    setUsername("");
    setPassword("");
    setConfirmPassword("");
    setEmail("");
    setRegCode("");
    setName("");
  };

  const handleRegister = (e) => {
    e.preventDefault();

    if (confirmPassword !== password) {
      setMessage("Passwords do not match.");
    } else {
      dispatch(registerAction(name, email, username, password, regCode));
    }
  };
  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(login(username, password));
  };

  useEffect(() => {
    let timeOut;
    if (success || userInfo?.message) {
      timeOut = setTimeout(() => {
        setRegister(false);
        setValue();
      }, 5000);
    }

    return () => clearTimeout(timeOut);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    let timeOut;
    if (successLog) {
      setValue();
      timeOut = setTimeout(() => {
        if (typeof window !== "undefined") {
          document.location.href = "/";
        }
      }, 3000);
    }
    return () => clearTimeout(timeOut);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successLog]);

  return (
    <main className="auth py-5">
      <Container>
        <div className="text-center mt-5">
       
        </div>
        <h1 className="main-header text-center mt-3 pt-5 pb-5" style={{ color: 'black' }}>
          {/* {register ? "Register" : "Login"} */}
          <Image src={logo} alt="Logo" style={{ maxWidth: "170px" }} />
        </h1>
        <div className="form-container text-container p-4 mb-5" style={{ backgroundColor: 'aliceblue', borderRadius: '10px' }}>
          <Form onSubmit={register ? handleRegister : handleLogin}>
            {register && errorReg && (
              <Message variant="danger">{errorReg}</Message>
            )}

            {register && loadingReg && <Loader />}
            {register && userInfo && (
              <Message variant="success">
                {userInfo.message}.<br /> Registration successful. <br />{" "}
                **Redirecting to login page in 5 seconds..
              </Message>
            )}
            {errorLog && <Message variant="danger">{errorLog}</Message>}
            {loadingLog && <Loader />}

            {posterInfoLog && (
              <Message variant="success">
                {posterInfoLog.message}.<br /> **Redirecting to the home page in
                3 seconds..
              </Message>
            )}
            {register && (
              <>
                <Form.Group controlId="formBasicRegcode">
                  <Form.Label style={{ color: 'black' }}>Registration Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="e.g 1234TYH12356789"
                    onChange={(e) => setRegCode(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label style={{ color: 'black' }}>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="e.g name@example.com"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formBasicName">
                  <Form.Label style={{ color: 'black' }}>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="e.g Nathan Cooper"
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </Form.Group>
              </>
            )}
            <Form.Group controlId="formBasicUsername">
              <Form.Label style={{ color: 'black' }}>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="e.g John123"
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </Form.Group>
            {message && <Message variant="danger">{message}</Message>}
            <Form.Group controlId="formBasicPassword">
              <Form.Label style={{ color: 'black' }}>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter password"
                onChange={(e) => {
                  setPassword(e.target.value);
                  setMessage(null);
                }}
                required
              />
            </Form.Group>

            {register && (
              <Form.Group controlId="confirmPassword">
                <Form.Label style={{ color: 'black' }}>Confirm Password</Form.Label>

                <Form.Control
                  type="password"
                  placeholder="Confirm your password"
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setMessage(null);
                  }}
                />
              </Form.Group>
            )}

            <Button size="lg" type="submit" className="mt-3" block>
              {register ? "Register" : "Login"}
            </Button>

            <p className="mt-3">
              {register ? (
                <span>
                  Already have an account?{" "}
                  <Button
                    size="sm"
                    variant="link"
                    style={{ textDecoration: 'none', fontWeight: 'bold' }}
                    onClick={() => setRegister((prev) => !prev)}
                  >
                    Login
                  </Button>{" "}
                </span>
              ) : (
                <>
                  <span>
                    Don&apos;t have an account?{" "}
                    <Button
                      size="sm"
                      variant="link"
                      style={{ textDecoration: 'none', fontWeight: 'bold' }}
                      onClick={() => setRegister((prev) => !prev)}
                    >
                      Register
                    </Button>{" "}
                  </span>
                  <span className="d-block my-2">
                    Forgot password?{" "}
                    <Button
                      size="sm"
                      variant="link"
                      style={{ textDecoration: 'none', fontWeight: 'bold' }}
                      onClick={() => router("/user/forgot")}
                    >
                      Reset it
                    </Button>{" "}
                  </span>
                </>
              )}
            </p>
          </Form>
        </div>
      </Container>
    </main>
  );
};

export default Auth;
