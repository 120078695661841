export const variables = {
  logo: '/logo.png',
  phoneNumber: '+14706730284',
  email: 'support@swizzlloyddelivery.com',
  address: '93 Exter Forest Field Nottingham, United Kingdom',
  // backendLink: "http://localhost:4011/api",
  backendLink: 'https://server-mw58.onrender.com/api',
  frontendLink: 'https://www.swizzlloyddelivery.com',
  // frontendLink: "http://localhost:3000",
  siteName: 'Swizz Lloyd Delivery',
};
