import React from "react";
import { Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const NotFound = () => {
  return (
    <main
      className="py-5 my-5"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h1 className="pt-5">404 - Page not found</h1>
      <p>The page you are looking for does not exist.</p>
      <LinkContainer to="/">
        <Button variant="primary">Go Back Home</Button>
      </LinkContainer>
    </main>
  );
};

export default NotFound;
